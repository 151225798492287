.App {
  text-align: left;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ol {
  list-style: none;
  display: flex;
  align-items: center;
}

.breadcrumb-separator {
  color: #333;
  margin: auto 6px;
  user-select: none;
}

.breadcrumb-collapser {
  display: flex;
  align-items: center;
}

.breadcrumb-collapser svg {
  cursor: pointer;
  transition: all 0.2s ease-out;
  color: #000;
  width: 25px;
  height: 25px;
}

.breadcrumb-collapser svg:hover,
.breadcrumb-collapser svg:focus {
  color: #999;
  transform: scale(1.15);
}

.breadcrumb-collapser svg:active {
  color: #333;
}

a {
  text-decoration: none;
  font-weight: 400;
  color: #424548;
}
a:hover {
  color: #1da2b3;
}